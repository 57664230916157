import * as React from "react"
import Seo from "../../components/seo"
import AppLayout from "../../components/app/app-layout"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"
import {BackToFaqHome} from "../../components/common/faqPage";
import AppleStoreButton from "../../components/common/apple_store_button";
import GooglePlayButton from "../../components/common/google_play_button";

const HowToUsePromotion = () => {
  const {t} = useTranslation();
  const title = t("howToUsePromotion.title");

  return (
    <AppLayout>
      { /* @todo: 暫定的にtailwindのcdnで対応している Gatsbyをv4からv5系にupdateして、tailwindをインストールする */}
      <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
      <Seo title={title} isIndex={false} pagePath={"/product/how-to-use-promotion"}/>

      <article className="article-container page-article-main">
        <h1 className="text-3xl font-bold mb-6">{title}</h1>

        <section>
          <h2 className="text-2xl font-semibold mb-4">アカウントのご準備</h2>
          <div className="mb-10">
            <h3>1. 最新のアプリをご準備ください</h3>
            <p>「タッチであそぼ！あかまるどれかな」の最新バージョンをご準備ください。</p>
            <p>App StoreまたはGoogle Playより、ダウンロードし、インストールします。</p>
            <p className="text-center download-buttons">
              <AppleStoreButton/>
              <GooglePlayButton/>
            </p>
          </div>

          <div className="mb-10">
            <h3>2. アプリ内でアカウント作成してください</h3>
            <p>インストールが完了後、アプリを起動します。ホーム画面から、[設定] ボタンをクリックし [設定画面]
              に進みます。</p>
            <p>[プロモーションコードをお持ち方はこちら] をクリックし、アカウント登録画面に移動します。</p>
            <p>必要な情報を入力して新しいアカウントを作成します。</p>
          </div>

          <div className="mb-10">
            <h3>3. アカウント確認を実施します</h3>
            <p>アカウント作成後、登録したメールアドレスに宛にアカウントの確認メールが届きます。メール内のリンクをクリックして、アカウントを確認・有効化します。</p>
          </div>

        </section>

        <section>
          <h2>プロモーションコードの適用</h2>
          <div className="mb-10">
            <h3>1. 作成したアカウントでログインします</h3>
            <p>
              <a href="https://promotion.dan-ran.co.jp">promotion.dan-ran.co.jp</a>にアクセスし、先ほど作成したアカウントの情報を使用してログインします。
            </p>
          </div>

          <div className="mb-10">
            <h3>2. プロモーションコードを入力します</h3>
            <p>ログイン後、Web画面内のプロモーションコード入力欄に、受け取ったプロモーションコードを入力します。</p>
          </div>

          <div className="mb-10">
            <h3>3. 特典の反映を確認します</h3>
            <p>プロモーションコードが正常に適用されたことを確認するために、再度アプリに戻ります。適用された特典や割引が反映されていることを確認します。</p>
          </div>

        </section>
        <BackToFaqHome/>
      </article>
    </AppLayout>
  );
}

export default HowToUsePromotion

export const pageQuery = graphql`
query ($language: String!) {
  locales: allLocale(filter
:
  {
    language: {
      eq: $language
    }
  }
)
  {
    edges
    {
      node
      {
        ns
        data
        language
      }
    }
  }
}
`;
